import React from "react";
import "./testimonials.css";
import { ImStarFull } from "react-icons/im";

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h2>
        Komis Ślubny Plus Size poszczycić się może już ponad 150
        pięciogwiazdkowymi ocenami
      </h2>
      <h2>
        <ImStarFull />
        <ImStarFull />
        <ImStarFull />
        <ImStarFull />
        <ImStarFull />
      </h2>
      <div className="container testimonials__container">
        <div className="testimonial__block">
          <p>
            Suknie piękne, wybór ogromny a Kasia to cudowna osoba. Dziś byłam
            wybrać swoją suknie i nigdy w życiu nie spotkałam się z tak ciepłą i
            szczerą osobą❤️❤️❤️. Wszystkim Paniom gorąco POLECAM ❤️❤️ ~Oliwia
          </p>
        </div>
        <div className="testimonial__block">
          <p>
            Przemiła i pomocna Kasia😍 Szeroki wybór fasonów i rozmiarów sukien
            ślubnych. W komisie czujesz się swobodnie i możesz podjąć ważną
            decyzję bez presji i nacisków. Kto nie był szczerze polecam!!! Tego
            wszystkiego nie sposób opisać, to trzeba zobaczyć😊😊 ~Paulina
          </p>
        </div>
        <div className="testimonial__block">
          <p>
            Kasia jest niesamowitą kobietą! ❤️ Cieszę się, że trafiłam do
            Komisu. Od wejścia można poczuć wspaniałą atmosferę, która pozwala
            się rozluźnić. 🙂 Wybór sukni był niezapomnianym przeżyciem, chociaż
            już po przymierzeniu pierwszej sukni wiedziałam, że to ta jedyna 🤍
            Nie czułam presji, że muszę coś kupić. Jeśli wyglądałam
            niekorzystnie to Kasia od razu odradzała dany model. A przede
            wszystkim – u Kasi znikają kompleksy! Dziewczyny, polecam z całego
            serca! ❤️ ~Katarzyna
          </p>
        </div>
        <div className="testimonial__block">
          <p>
            Z całego serca polecam komis jako strona sprzedająca suknie. Pani
            Kasia jest super w kontakcie, szybko ustaliłyśmy szczegóły,
            odpowiedziała na wszelkie pytania. Suknia sprzedała się w niewiele
            ponad tydzień! Od razu dostałam informację zwrotna. Mam nadzieję, że
            przyniesie nowej właścicielce dużo radości, dla mnie była to
            wymarzona suknia! ~Katarzyna
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
