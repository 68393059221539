import React from "react";
import "./about.scss";

const About = () => {
  return (
    <div className="about">
      <h1>O Komisie Ślubnym Plus Size Pani Kasi</h1>
      <p>
        Szukając idealnej sukni ślubnej plus size, wiele przyszłych panien
        młodych może natknąć się na wyjątkowe miejsce, które oferuje szeroki
        wybór sukienek w rozmiarach od 40 do 60. Miejsce to to Komis Ślubny Plus
        Size, który znajduje się niedaleko Warszawy, w Ozarowie Mazowieckim. To
        niezwykłe miejsce przyciąga swoją wyjątkowością i dedykacją
        właścicielki, Pani Kasi, która traktuje każdą swoją klientkę jak
        prawdziwą księżniczkę.
      </p>
      <p>
        Komis Ślubny Plus Size Pani Kasi znajduje się w urokliwym domu na
        poddaszu. To przytulne miejsce, gdzie atmosfera jest pełna ciepła i
        serdeczności. Panna młoda Plus Size wchodząc do tego wyjątkowego komisu
        od razu czuje się wyjątkowo i mile widziana. Pani Kasia zadbała o każdy
        szczegół, tworząc miejsce, które jest zarówno eleganckie, jak i
        przyjazne.
      </p>
      <p>
        <h2>Szeroki Wybór Sukienek Ślubnych Plus Size</h2>
      </p>
      <p>
        Komis Ślubny Pani Kasi specjalizuje się w sukniach ślubnych plus size.
        Oferuje szeroki wybór sukienek, które pasują do różnych gustów i stylów
        przyszłych panien młodych. Niezależnie od tego, jakiego stylu szukasz,
        tutaj znajdziesz coś, co spełni Twoje marzenia ślubne.
      </p>
      <p>
        <h2>Dedykowana Obsługa Klienta</h2>
      </p>
      <p>
        To, co wyróżnia Komis Ślubny Pani Kasi, to dedykowana obsługa klienta.
        Pani Kasia jest nie tylko ekspertem w dziedzinie sukien ślubnych w
        dużych rozmiarach, ale także sercem tego miejsca. Dbając o swoje
        klientki, słucha ich potrzeb, preferencji i pomaga im znaleźć idealną
        suknię, która podkreśli ich piękno i wyjątkowość. Jej podejście do
        każdej klientki jest wyjątkowe i pełne troski.
      </p>
      <p>
        <h2>Każda Panna Młoda to Księżniczka</h2>
      </p>
      <p>
        W Komisie Ślubnym Pani Kasi każda panna młoda jest traktowana jak
        prawdziwa księżniczka. Pani Kasia rozumie, jak ważny jest wybór sukni
        ślubnej, dlatego poświęca czas, aby pomóc każdej klientce znaleźć suknię
        marzeń. Jej zaangażowanie i pasja do mody ślubnej czynią to miejsce
        wyjątkowym.
      </p>
      <p>
        Jeśli jesteś przyszłą panną młodą o rozmiarze od 40 do 60 i szukasz
        idealnej sukni ślubnej, Komis Ślubny Plus Size w Ozarowie Mazowieckim to
        miejsce, które warto odwiedzić. Tutaj znajdziesz nie tylko piękne
        suknie, ale także serdeczność i dedykację właścicielki, która sprawi, że
        poczujesz się wyjątkowo w tym ważnym dniu.
      </p>
    </div>
  );
};

export default About;
