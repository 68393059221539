import React from "react";
import "./owner.css";
import owner from "../../assets/img/owner.png";
import { Link } from "react-router-dom";

const Owner = () => {
  return (
    <section id="owner">
      <h2>Kilka słów o mnie...</h2>
      <div className="container owner__container">
        <div className="extra__div"></div>
        <div className="owner__image">
          <img src={owner} alt="właścicielka komisu ślubnego" />
        </div>
        <div className="owner__content">
          <p>
            Cześć, jestem Kasia. Od kilku lat Prowadzę dla Was Komis Ślubny Plus
            Size. Na codzień jestem aktywną mamą trójki dzieci, kocham
            podróżować i czytać książki o ciekawych kobietach. Zapraszam Was do
            swojego magicznego, ślubnego świata. Znajdziecie mój komis w
            Ożarowie Mazowieckim.
          </p>
          <p>Do zobaczenia</p>
          <p>Kasia ❤❤</p>
          <button className="btn btn-primary ">
            <Link to="/blog">Mój Blog</Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Owner;
