import React from "react";
import Header from "../../components/header/Header";
import About from "../../components/about/About";

import Owner from "../../components/owner/Owner";
import Testimonials from "../../components/testimonials/Testimonials";
import Location from "../../components/location/Location";
import Intro from "../../components/intro/Intro";

const Home = () => {
  return (
    <>
      <Header />
      <Intro />
      <Owner />
      <About />
      <Testimonials />
      <Location />
    </>
  );
};

export default Home;
