import React from "react";
import "./about.scss";

const About = () => {
  return (
    <section id="about">
      <h2>O komisie...</h2>
      <div className="container about__info">
        <div className="item-a">
          <img
            src={require("../../assets/img/suknia_3.webp")}
            alt="Suknia ślubna 3"
          />
          <p>
            Komis Ślubny Plus Size to wyjątkowe i magiczne miejsce gdzie Panna
            Młoda Plus Size znajdzie dla siebie wymarzoną suknię. W każdym stylu
            czy kolorze Będzie mogła przymierzyc suknię a nie tylko dolozyc do
            siebie.
          </p>
        </div>
        <div className="item-b">
          <img
            src={require("../../assets/img/suknia_2.webp")}
            alt="Suknia ślubna 2"
          />
          <p>
            W ofercie cały czas jest około 200 sztuk pięknych sukien ślubnych.
            Ponieważ Panny Młode przyjeżdżają do mnie z całej Polski, staje na
            głowie, żeby miały gigantyczny wybór na przymiarkach.
          </p>
        </div>
        <div className="item-c">
          <p>
            Staram sie całym sercem stworzyć wspaniałą koleżeńską atmosferę, tak
            żeby czas spędzony w moich progach byl wyjątkowy.
          </p>
          <img
            src={require("../../assets/img/suknia_1.jpg")}
            alt="Suknia ślubna 1"
          />
          <p>
            Nie mam najmniejszych wątpliwości, że moje suknie i ja sprawimy, ze
            każda panna młoda poczuje sie jak księżniczka.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
