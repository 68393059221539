import React from "react";
import "./intro.css";
import { FaHeart, FaShoppingBag } from "react-icons/fa";

const Intro = () => {
  return (
    <section id="intro" className="intro-section">
      <h2 className="intro-heading">Kilka słów o tobie...</h2>
      <div className="intro-content">
        <p>
          Jeśli zostałaś źle potraktowana w salonie ślubnym z powodu rozmiaru i
          już odechciało Ci się szukać sukni ślubnej to wiedz, że jest takie
          miejsce, gdzie Ja stanę na głowie, żebyś poczuła się Piękna i
          Wyjątkowa bo jak każda Kobieta masz prawo wyglądać wspaniale w dniu
          swojego ślubu. Zapraszam serdecznie na przymiarki <FaShoppingBag />{" "}
          <FaHeart />
        </p>
      </div>
    </section>
  );
};

export default Intro;
