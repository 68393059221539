import PhotoAlbum from "react-photo-album";
import "./gallery.scss";
import { Helmet } from "react-helmet";

const Gallery = () => {
  const width = 800;
  const height = (width * 6) / 4;
  const photos = [
    {
      src: require("../../assets/img/suknia_1.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size rozmiar 48-54",
      title: "Suknia ślubna plus size rozmiar 48-54",
    },
    {
      src: require("../../assets/img/suknia_2.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna w rozmiarze xxl",
      title: "Suknia ślubna w rozmiarze xxl",
    },
    {
      src: require("../../assets/img/suknia_3.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna boho w dużej rozmiarówce",
      title: "Suknia ślubna boho w dużej rozmiarówce",
    },
    {
      src: require("../../assets/img/suknia_4.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych",
      title: "Suknia ślubna dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_5.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size rozmiar 48-54",
      title: "Suknia ślubna plus size rozmiar 48-54",
    },
    {
      src: require("../../assets/img/suknia_6.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna duże rozmiary",
      title: "Suknia ślubna duże rozmiary",
    },
    {
      src: require("../../assets/img/suknia_7.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna xxl",
      title: "Suknia ślubna xxl",
    },
    {
      src: require("../../assets/img/suknia_8.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystej",
      title: "Suknia ślubna dla puszystej",
    },
    {
      src: require("../../assets/img/suknia_9.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna w rozmiarze xxl",
      title: "Suknia ślubna w rozmiarze xxl",
    },
    {
      src: require("../../assets/img/suknia_10.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna xxxl",
      title: "Suknia ślubna xxxl",
    },
    {
      src: require("../../assets/img/suknia_11.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size rozmiar 45",
      title: "Suknia ślubna plus size rozmiar 45",
    },
    {
      src: require("../../assets/img/suknia_12.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size rozmiar 58",
      title: "Suknia ślubna plus size rozmiar 58",
    },
    {
      src: require("../../assets/img/suknia_13.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna duże rozmiary 40-60",
      title: "Suknia ślubna duże rozmiary 40-60",
    },
    {
      src: require("../../assets/img/suknia_14.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna xxl",
      title: "Suknia ślubna xxl",
    },
    {
      src: require("../../assets/img/suknia_15.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystej pięknej panny młodej",
      title: "Suknia ślubna dla puszystej pięknej panny młodej",
    },
    {
      src: require("../../assets/img/suknia_16.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size rozmiar 54",
      title: "Suknia ślubna plus size rozmiar 54",
    },
    {
      src: require("../../assets/img/suknia_17.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna księżniczka duży rozmiar",
      title: "Suknia ślubna księżniczka duży rozmiar",
    },
    {
      src: require("../../assets/img/suknia_18.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna princeska duży rozmiar",
      title: "Suknia ślubna princeska duży rozmiar",
    },
    {
      src: require("../../assets/img/suknia_19.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z koronkowymi rękawami",
      title: "Suknia ślubna plus size z koronkowymi rękawami",
    },
    {
      src: require("../../assets/img/suknia_20.jpg"),
      width: width,
      height: height,
      alt: "Elegancka suknia ślubna dla puszystych",
      title: "Elegancka suknia ślubna dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_21.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z dekoltem w kształcie serca",
      title: "Suknia ślubna plus size z dekoltem w kształcie serca",
    },
    {
      src: require("../../assets/img/suknia_22.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna w stylu boho dla puszystych",
      title: "Suknia ślubna w stylu boho dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_23.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z głębokim dekoltem",
      title: "Suknia ślubna plus size z głębokim dekoltem",
    },
    {
      src: require("../../assets/img/suknia_24.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z odkrytymi ramionami",
      title: "Suknia ślubna dla puszystych z odkrytymi ramionami",
    },
    {
      src: require("../../assets/img/suknia_25.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size w stylu rustykalnym",
      title: "Suknia ślubna plus size w stylu rustykalnym",
    },
    {
      src: require("../../assets/img/suknia_26.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z tiulową spódnicą",
      title: "Suknia ślubna dla puszystych z tiulową spódnicą",
    },
    {
      src: require("../../assets/img/suknia_27.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z koronkowym gorsetem",
      title: "Suknia ślubna plus size z koronkowym gorsetem",
    },
    {
      src: require("../../assets/img/suknia_28.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych w stylu vintage",
      title: "Suknia ślubna dla puszystych w stylu vintage",
    },
    {
      src: require("../../assets/img/suknia_29.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z długimi rękawami",
      title: "Suknia ślubna plus size z długimi rękawami",
    },
    {
      src: require("../../assets/img/suknia_30.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z asymetrycznym krojem",
      title: "Suknia ślubna dla puszystych z asymetrycznym krojem",
    },
    {
      src: require("../../assets/img/suknia_31.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z dekoltem w kształcie serca",
      title: "Suknia ślubna plus size z dekoltem w kształcie serca",
    },
    {
      src: require("../../assets/img/suknia_32.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna w stylu boho dla puszystych",
      title: "Suknia ślubna w stylu boho dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_33.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z głębokim dekoltem",
      title: "Suknia ślubna plus size z głębokim dekoltem",
    },
    {
      src: require("../../assets/img/suknia_34.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z odkrytymi ramionami",
      title: "Suknia ślubna dla puszystych z odkrytymi ramionami",
    },
    {
      src: require("../../assets/img/suknia_35.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size w stylu rustykalnym",
      title: "Suknia ślubna plus size w stylu rustykalnym",
    },
    {
      src: require("../../assets/img/suknia_36.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z tiulową spódnicą",
      title: "Suknia ślubna dla puszystych z tiulową spódnicą",
    },
    {
      src: require("../../assets/img/suknia_37.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z koronkowym gorsetem",
      title: "Suknia ślubna plus size z koronkowym gorsetem",
    },
    {
      src: require("../../assets/img/suknia_38.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych w stylu vintage",
      title: "Suknia ślubna dla puszystych w stylu vintage",
    },
    {
      src: require("../../assets/img/suknia_39.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z długimi rękawami",
      title: "Suknia ślubna plus size z długimi rękawami",
    },
    {
      src: require("../../assets/img/suknia_40.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z asymetrycznym krojem",
      title: "Suknia ślubna dla puszystych z asymetrycznym krojem",
    },
    {
      src: require("../../assets/img/suknia_41.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z dekoltem w kształcie serca",
      title: "Suknia ślubna plus size z dekoltem w kształcie serca",
    },
    {
      src: require("../../assets/img/suknia_42.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna w stylu boho dla puszystych",
      title: "Suknia ślubna w stylu boho dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_43.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z głębokim dekoltem",
      title: "Suknia ślubna plus size z głębokim dekoltem",
    },
    {
      src: require("../../assets/img/suknia_44.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z odkrytymi ramionami",
      title: "Suknia ślubna dla puszystych z odkrytymi ramionami",
    },
    {
      src: require("../../assets/img/suknia_45.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size w stylu rustykalnym",
      title: "Suknia ślubna plus size w stylu rustykalnym",
    },
    {
      src: require("../../assets/img/suknia_46.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna dla puszystych z tiulową spódnicą",
      title: "Suknia ślubna dla puszystych z tiulową spódnicą",
    },
    {
      src: require("../../assets/img/suknia_47.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z koronkowym gorsetem",
      title: "Suknia ślubna plus size z koronkowym gorsetem",
    },
    {
      src: require("../../assets/img/suknia_48.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z aplikacjami kwiatowymi",
      title: "Suknia ślubna plus size z aplikacjami kwiatowymi",
    },
    {
      src: require("../../assets/img/suknia_49.jpg"),
      width: width,
      height: height,
      alt: "Suknia ślubna plus size z trenem",
      title: "Suknia ślubna plus size z trenem",
    },
    {
      src: require("../../assets/img/suknia_50.jpg"),
      width: width,
      height: height,
      alt: "Romantyczna suknia ślubna dla puszystych",
      title: "Romantyczna suknia ślubna dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_51.jpg"),
      width: width,
      height: height,
      alt: "suknia ślubna dla panny młodej plus size",
      title: "suknia ślubna dla panny młodej plus size",
    },
    {
      src: require("../../assets/img/suknia_52.jpg"),
      width: width,
      height: height,
      alt: "Romantyczna suknia ślubna dla puszystych",
      title: "Romantyczna suknia ślubna dla puszystych",
    },
    {
      src: require("../../assets/img/suknia_53.jpg"),
      width: width,
      height: height,
      alt: "suknie ślubne w dużych rozmiarach",
      title: "suknie ślubne w dużych rozmiarach",
    },
  ];

  return (
    <section className="gallery-container">
      <Helmet>
        <meta
          name="description"
          content="Witaj w galerii mojego salonu ślubnego plus size! Odkryj naszą wyjątkową kolekcję pięknych sukien ślubnych w dużych rozmiarach, które podkreślają naszą naturalną piękność i elegancję. Przejrzyj nasze zdjęcia, by zainspirować się różnorodnymi stylami i designami sukien, które dostępne są w naszym komisie. Zapraszam Cię do zanurzenia się w świecie mody ślubnej plus size i znalezienia swojej wymarzonej sukni na ten wyjątkowy dzień. Oto miejsce, gdzie każda z nas może poczuć się pewnie, pięknie i doceniona w całej swojej wyjątkowości. Przygotuj się na wspaniałą podróż odkrywania najpiękniejszych kreacji dla nas, pięknych Panien Młodych w każdym rozmiarze."
        />
      </Helmet>

      <h2>Suknie</h2>
      <h5>Przez mój salon przewinęły się już setki sukien oto kilka z nich</h5>
      <div className="photos-container">
        <PhotoAlbum layout="columns" photos={photos} />
      </div>
    </section>
  );
};

export default Gallery;
