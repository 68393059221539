import React from "react";
import "./index.css";
import Home from "./pages/home/home.jsx";
import Gallery from "./pages/gallery/gallery.jsx";
import Blog from "./pages/blog/blog.jsx";
import News from "./pages/news/news.jsx";
import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import About from "./pages/about/about.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <meta
            name="description"
            content="Witaj w moim wyjątkowym komisie ślubnym plus size! Oferuję szeroki wybór pięknych sukien ślubnych w dużych rozmiarach i sukni plus size, dopasowanych do różnych stylów i preferencji. Moja misja to sprawić, by każda Panna Młoda o wyjątkowych kształtach czuła się pewnie, elegancko i olśniewająco w najważniejszym dniu swojego życia. Odkryj moją kolekcję sukien, stworzoną z myślą o Tobie i Twoich indywidualnych potrzebach, i zrealizuj swoje marzenia o wymarzonym ślubie. Zapraszam Cię do mojego komisu, gdzie mój doświadczony zespół pomoże Ci znaleźć idealną suknię plus size, która podkreśli Twój wyjątkowy charakter i sprawi, że zabłyśniesz na ślubnym kobiercu."
          />
        </Helmet>
        <Nav />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/blog" element={<Blog />}></Route>
          <Route exact path="/gallery" element={<Gallery />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/news" element={<News />}></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
