import React from "react";
import Slider from "react-slick";
import "./header.css";
import suknia3 from "../../assets/img/suknia_3.webp";
import suknia4 from "../../assets/img/suknia_4.webp";
import suknia5 from "../../assets/img/suknia_5.webp";
import suknia6 from "../../assets/img/suknia_6.webp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = () => {
  const carouselImages = [suknia3, suknia4, suknia5, suknia6];

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
  };

  return (
    <header>
      <div className="header__container">
        <h1 className="header__text">
          Komis Ślubny Plus Size Katarzyna Furgał
        </h1>
        <Slider {...settings}>
          {carouselImages.map((image, index) => (
            <div key={index}>
              <img
                className="carousel__image"
                src={image}
                alt={`suknia ślubna plus size ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </header>
  );
};

export default Header;
