import React from "react";
import "./nav.css";
import { Link } from "react-router-dom";
import LOGO from "../../assets/logo.png";

const Nav = ({ activeNav, setActiveNav }) => {
  const handleNavClick = (navItem, event) => {
    event.preventDefault();
    setActiveNav(navItem);
  };

  return (
    <div className="topnav">
      <div className="logo-container">
        <img
          className="logo"
          src={LOGO}
          alt="komis slubny plus size Katarzyna Furgał"
        />
      </div>
      <ul>
        <li
          onClick={(event) => handleNavClick("#home", event)}
          className={activeNav === "#home" ? "active" : ""}
        >
          <Link to="/">Home</Link>
        </li>
        <li
          onClick={(event) => handleNavClick("#news", event)}
          className={activeNav === "#news" ? "active" : ""}
        >
          <Link to="/news">Aktualności</Link>
        </li>
        <li
          onClick={(event) => handleNavClick("#gallery", event)}
          className={activeNav === "#gallery" ? "active" : ""}
        >
          <Link to="/gallery">Galeria</Link>
        </li>
        <li
          onClick={(event) => handleNavClick("#about", event)}
          className={activeNav === "#about" ? "active" : ""}
        >
          <Link to="/about">O komisie</Link>
        </li>
        <li
          onClick={(event) => handleNavClick("#blog", event)}
          className={activeNav === "#blog" ? "active" : ""}
        >
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
