import React from "react";
import "./location.css";
import { AiFillInstagram } from "react-icons/ai";
import { BsMessenger, BsFillTelephoneFill } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

const Location = () => {
  return (
    <section id="location">
      <h2>Serdecznie zaprszam na przymiarki</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <BsFillTelephoneFill className="contact__option-icon" />
            <h4>Telefon</h4>
            <h5>+48 505 944 233</h5>
            <a href="tel:+48 505 944 233">Zadzwoń</a>
          </article>
          <article className="contact__option">
            <BsMessenger className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>Komis Ślubny Plus Size</h5>
            <a
              href="https://www.facebook.com/komisslubnyplussize"
              target="_blank"
              rel="noreferrer"
            >
              Skontaktuj się
            </a>
          </article>
        </div>
        <div className="contact__options">
          <article className="contact__option">
            <AiFillInstagram className="contact__option-icon" />
            <h4>Instagram</h4>
            <h5>@komis_slubny_plus_size</h5>
            <a
              href="https://www.instagram.com/komis_slubny_plus_size/"
              target="_blank"
              rel="noreferrer"
            >
              Zaobserwuj komis
            </a>
          </article>
          <article className="contact__option">
            <FaTiktok className="contact__option-icon" />
            <h4>TikTok</h4>
            <h5>komis.slubny.plus.size</h5>
            <a
              href="https://www.tiktok.com/@komis.slubny.plus.size"
              target="_blank"
              rel="noreferrer"
            >
              Bądź na bieżąco
            </a>
          </article>
        </div>
        <div className="map_container">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width="600"
                height="500"
                title="lokalizacja komisu"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=aksamitna%2021,%20O%C5%BCar%C3%B3w%20Mazowiecki&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
