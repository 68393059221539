import React from "react";
import "./footer.css";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__name">
        Komis Ślubny Plus Size Katarzyna Furgał
      </a>
      <p>komisslubnyplussize@gmail.com</p>
      <p>+48 505 944 233</p>
      <p>Aksamitna 21 Ożarów Mazowiecki 05-850</p>

      <div className="footer__socials">
        <a href="https://www.facebook.com/komisslubnyplussize">
          <AiFillFacebook />
        </a>
        <a href="https://www.instagram.com/komis_slubny_plus_size/">
          <AiFillInstagram />
        </a>
        <a href="https://www.tiktok.com/@komis.slubny.plus.size">
          <FaTiktok />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy;All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
