import React, { useEffect } from "react";
import "./news.scss";

const News = () => {
  useEffect(() => {
    // Załaduj skrypt TikTok
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Usuń skrypt przy odmontowaniu komponentu
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="news-container">
      <div className="facebook-container">
        <h1>Wiadomości z facebooka</h1>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkomisslubnyplussize&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=630654092472464"
          className="facebook-frame"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          title="Strona Facebook Komis Ślubny Plus Size"
        ></iframe>
      </div>
      <div className="tik-tok-container">
        <h1>Relacje z tiktoka</h1>
        <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@komis.slubny.plus.size"
          data-unique-id="komis.slubny.plus.size"
          data-embed-from="embed_page"
          data-embed-type="creator"
          style={{ maxWidth: "780px", minWidth: "288px" }}
        >
          <section>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.tiktok.com/@komis.slubny.plus.size?refer=creator_embed"
            >
              @komis.slubny.plus.size
            </a>
          </section>
        </blockquote>
      </div>
    </div>
  );
};

export default News;
